.admin-dashboard-containers {
    /* background-color: #fff9e6; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    /* min-height:100vh; */
    
}

/* .dashboard-wrapper{
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.approval-finished {
    font-size: 20px;
    font-family: 'ZhuqueFangsong-Regular';
}

.admin-logo {
    margin-top: 50px; 
    /* position: absolute;
    display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
   
}



.title-1 {
    font-size: 40px;
    font-family: 'ZhuqueFangsong-Regular';
}

.title-2 {
    font-size: 30px;
    text-decoration: underline;
    font-family: 'ZhuqueFangsong-Regular';
    width: 100%; /* Ensure the heading spans full width */
    text-align: center; 
} 





/* Grid layout for posts */
.post-container {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    justify-content: center;
    margin-top: 0px;
    width: 100%;
    gap: 20px;
    padding: 20px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 100px;

    /* margin-top: */
   
}


.post-contents {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    justify-content: center;
    margin-top: 0px;
    width: 100%;
    gap: 20px;
    padding: 20px;
    /* border: 2px solid red; */
    /* background-color: lightgrey; */
}


.approval-finished{
    display: flex;
    justify-content: center;
}




@media (min-width: 1200px) {
    .post-container {
        grid-template-columns: repeat(5, 1fr); 
    }
}


@media (min-width: 768px) and (max-width: 900px){
    .post-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}


@media (max-width: 767px)  {
    .post-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 400px) {
    .post-container {
        grid-template-columns: 1fr;  
    }
}

.reapprove-btn,
.delete-btn {
    width: 120px;
    font-family: 'ZhuqueFangsong-Regular';
    font-size: 20px;

}

.delete-btn {
    margin-left: 20px;
}

.reapprove-btn:hover,
.delete-btn:hover {
    cursor: pointer;
    background-color: brown;
    color:white;
}