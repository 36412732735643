.post-detail {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'ZhuqueFangsong-Regular';
        
     
}

.view-post .first-line,
.view-post .view-count,
.preview-header{
    font-size: 16px;
}

.preview-mode h1,
.view-post h1 {
        font-size: 22px;    
}

.preview-mode .description,
.view-post .description{
    font-size: 20px;
}

.header-post {
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
}

.edit-mode .category-option select {

    width: 150px;


}


.post-container {
    font-family: 'ZhuqueFangsong-Regular';
    position: absolute;
    /* margin-top: 20px; */
    top: 100px;
    left: 0; 
 

   

    display: flex;
    justify-content: center; /* Centers the post horizontally */
    align-items: center; /* Centers the post vertically, if desired */
    /* min-height: 100vh; */

    /* Optional: set a max-width to prevent the container from being too wide */
    /* width: 100%;  */
}

.view-post{
   margin-left: 10px;
   padding-right: 20px;
   
}

.post-description{
    margin-top: 30px;
}



/* Style for H1 */

.edit-mode .ql-size-large,
.view-post .ql-size-large,
.preview-mode .ql-size-large {
    font-size: 20px !important; /* Larger font size for H1 */
    /* font-weight: bold; */
    font-family: 'ZhuqueFangsong-Regular'; 
    margin-bottom: 0; 
}

/* Style for H2 */
.edit-mode .ql-size-small,
.view-post .ql-size-small,
.preview-mode .ql-size-small {
    font-size: 16px !important; /* Slightly smaller font for H2 */
    /* font-weight: bold; */
    font-family: 'ZhuqueFangsong-Regular', serif;
}


  

  .edit-mode .edit-description p,
  .view-post .post-description p,
  .preview-mode .description p {
    font-size: 18px;  
    font-family: 'ZhuqueFangsong-Regular'
  }

  .edit-mode .ql-size-false,
  .view-post .ql-size-false,
  .preview-mode .ql-size-false {
    font-size: 18px;  /* Define default font size for normal text */
    font-family: 'ZhuqueFangsong-Regular'
  }

  /* .edit-mode .edit-description ol,
  .view-post .post-description ol,
  .preview-mode .description ol,
  .edit-mode .edit-description ul,
  .view-post .post-description ul,
  .preview-mode .description ul{
    font-size: 18px; 
  } */

  

.edit-mode .edit-description ul,
  .view-post .post-description ul,
  .preview-mode .description ul {
    list-style-type: disc;
    margin-left:-10px;
    margin-bottom: 0;
    font-size: 18px;
}

.edit-mode .edit-description ol,
.view-post .post-description ol,
.preview-mode .description ol {
    list-style-type: decimal;
    margin-left:10px;
    margin-top: 0px;
    font-size: 18px;
}

  

  

.view-post-images img{
    margin: 0;
    width: 500px;
  }



.collection-heart{
  display: flex;
  justify-content: flex-end; /* Aligns the icon to the right */
  align-items: center; /* Centers it vertically */
  margin-right: 10px; /* Adjust the margin to move the icon */
}


.submit_btn,
.preview_btn,
.cancel_btn,
.edit_btn,
.back_edit,
.delete_btn,
.download-btn{
    font-size: 16px;
    /* margin-right:10px; */
    font-family: 'ZhuqueFangsong-Regular';
    font-weight: 600;
}

.submit_btn:hover,
.preview_btn:hover,
.cancel_btn:hover,
.edit_btn:hover,
.back_edit:hover,
.return_btn:hover,
.delete_btn:hover,
.download-btn:hover {
    cursor: pointer;
    font-weight: 600;
}

.back_edit{
    margin-bottom: 60px
}

.edit_btn,
.submit_btn,
.preview_btn {
    margin-right: 10px;
}


.btn-group {
    margin-top: 5px;
}

.image-del-btn{
    margin-left:-5px;
    font-size: 16px;
    background-color:#ffffff;
    color:rgb(0, 0, 0);
    border: solid 0.1px rgb(0, 0, 0);
    font-family: 'ZhuqueFangsong-Regular';
    font-weight: 600;
}

.image-del-btn:hover { 
    cursor: pointer;
}

.return_btn {
    /* background: none; */
    /* border: none; */
    cursor: pointer;
    margin-top: 15px;
    font-family: 'ZhuqueFangsong-Regular';
    
    
  }

  .bot-btn {
    margin-bottom: 50px;
  }
  

  .bot-btn {
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
    align-items: center; /* Center the button vertically if needed */
    margin-right: 10px; /* Adjust the right margin */
  }
  
  .return_btn {
    background-color: transparent; /* No background */
    border: none; /* No border */
    color: black; /* Default text color */
    font-size: 18px; /* Adjust font size */
    cursor: pointer; /* Change cursor to pointer on hover */
    padding: 10px; /* Adjust padding */
    /* transition: color 0.3s ease;  */
  }
  
  .return_btn:hover {
    color: #ff6600; /* Change color on hover */
  }


  .breadcrumb {
    font-size: 20px;
    font-family: 'ZhuqueFangsong-Regular';
}


  .breadcrumb a {
    color: rgb(0, 0, 0); /* Link color */
    text-decoration: none; /* Remove underline */
    padding: 0 8px 0 0; /* Add some space around each link */
    transition: color 0.3s ease; /* Smooth hover transition */

  }

  .breadcrumb a:hover {
    color: #ff6600; /* Change color on hover */
  }
  
  .breadcrumb .separator {
    color: #999; /* Color for separator (comma or arrow) */
    padding: 0 5px; /* Space around the separator */
  }


.figure-caption {
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: 0;
}

.caption-input {
    margin-top: 0px;
    margin-bottom:50px;
    font-weight: bold;
}

.preview-image,
.existing-image{
    width: 500px;
    margin: 0px
}

.category-option{
    margin-bottom: 20px; 
}


.preview-mode .description {
    margin-top: 20px;
}



.post-images p{
    text-align: left;
}

.view-comments {
    margin-bottom: 60px;
    margin-top: 0px;
}

.edit-mode .submit_btn,
.edit-mode .preview_btn,
.edit-mode .cancel_btn{
    margin-bottom: 70px;
}

.edit-mode input,
.ql-wrapper {
    width: 50vw;
}



@media(max-width: 600px) {

    .view-post{
        margin-left: -60px;
    }



    .preview-mode h1,
    .view-post h1 {
        font-size: 18px;    
}

    .view-post .first-line,
    .view-post .view-count{
        font-size: 12px;
    }
 

    .view-post .post-description,
    .figure-caption,
    .breadcrumb a{
        font-size: 16px;
    }

    .view-post .return_btn,
    .view-post .edit_btn,
    .view-post .delete_btn {
        font-size: 14px
    }

    .comment_input h2{
        font-size: 16px;
    }

    .comment-submit-btn{
        font-size: 14px;
    }

    .comment_input .comment-textarea,
    .reply-input{
        width: 300px;
    }

    .view-post-images img{
        width: 300px;
    }



    .edit-mode {
        margin-left:-50px;
        
    }

    .edit-mode input,
    .edit-mode textarea{
        width: 250px;
        font-size: 18px;
        
    }

    .ql-wrapper {
        /* margin-left: 10px;  */
        width: 257px;
       
    }

    /* .post-description {
        font-size: 20px;
    }    */


    .edit-mode .submit_btn,
    .edit-mode .preview_btn,
    .edit-mode .cancel_btn{
        font-size: 14px;
    }
    
    .edit-mode .submit_btn{
        margin-left: 10px;
    }

    .edit-mode .category-option select {

        width: 120px;
        font-size: 18px;

    }
   



    .note p{
        font-size: 18px;
    }

    .preview-mode{
        margin-left: -60px;
    }

    .preview-mode .preview-image,
    .preview-mode .existing-image{
        width: 300px;
    }

    .preview-mode .description{
        font-size: 16px;
    }

    /* .preview-mode button{
        font-size: 14px;
    } */

    .preview-mode .preview-header{
        font-size: 12px;     
    }

    .post-images p{
        font-size: 14px;
    }


/* Style for H1 */

.edit-mode .ql-size-large,
.view-post .ql-size-large,
.preview-mode .ql-size-large {
    font-size: 18px !important; /* Larger font size for H1 */
    /* font-weight: bold; */
    font-family: 'ZhuqueFangsong-Regular'; 
    margin-bottom: 0; 
}

/* Style for H2 */
.edit-mode .ql-size-small,
.view-post .ql-size-small,
.preview-mode .ql-size-small {
    font-size: 14px !important; /* Slightly smaller font for H2 */
    /* font-weight: bold; */
    font-family: 'ZhuqueFangsong-Regular', serif;
}

.edit-mode .edit-description p,
.view-post .post-description p,
.preview-mode .description p {
  font-size: 16px;  
  font-family: 'ZhuqueFangsong-Regular'
}


  
  /* Style for normal text */
  .edit-mode .ql-size-false,
  .view-post .ql-size-false,
  .preview-mode .ql-size-false {
    font-size: 16px;  /* Define default font size for normal text */
    font-family: 'ZhuqueFangsong-Regular'
  }

  .edit-mode .edit-description ol,
  .edit-mode .edit-description ul,
  .view-post .post-description ol,
  .preview-mode .description ol,
  .view-post .post-description ul,
  .preview-mode .description ul{
    font-size: 16px; 
  }
  
 


}


/* For screens between 1000px and 1500px */
@media (min-width: 1000px) {
    .view-post,
    .preview-mode,
    .edit-mode{
      margin-left: 30vw;  /* Apply the 20% margin when the screen width is between 1000px and 1500px */
    }
  }
