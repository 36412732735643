

.readme{
    font-family: 'ZhuqueFangsong-Regular';

    font-size: 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    
}

.readme li{
    margin-bottom: 10px;;
}

.home-screen {
    width: 500px;
}

@media (max-width: 768px) {

    .readme 
    {font-size: 16px;
    }

    .home-screen {
        width:80%;
    }
    

}