.category-posts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'ZhuqueFangsong-Regular';
}


.header-posts {
    position: fixed;
    top: 0;
    z-index: 100;
}

/* .back-btn{
  cursor: pointer;
  background-color: antiquewhite;
  object-fit: fit;
  display: flex;
  justify-content: flex-start;
} */


.back-btn:hover{
  cursor: pointer;
}
.header-line{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.container-posts {
    position: absolute;
    top: 100px;
    left: 20vw;
    margin-top: 0px;
    font-size: 18px;
}


.container-posts h3,
.container-posts .posts-table,
.container-posts .no-posts{
    margin-left: 5px;
}


table {
    width: 100%;
    border-collapse: collapse; /* Remove gaps between table cells */
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Add a line between rows */
  }
  
  th {
    background-color: #f0f8ff; /* Light background for header */
    font-weight: bold;
    text-align: left;
  }
  
  td {
    text-align: left;
  }
  
  /* Set column widths */
  .title-column {
    width: 500px;
  }

  .views-column {
    width: 50px;
  }

  .collections-column {
    width: 50px;
  }

  .created-column {
    width: 200px;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Add stripe effect for rows */
  }
  
  tbody tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
  }

  .posts-table {
    margin-bottom: 50px;
}




  @media (max-width: 768px) {

    .container-posts {
      left: 10px;
  }


    .container-posts h3,
    .container-posts .posts-table,
    .container-posts .no-posts{
        display: flex;
        justify-self: center;
        flex-direction: column;
        align-items: center;
    }

    .container-posts h3{
        font-size: 18px;
    }

    .posts-table th, 
    .posts-table td,  
    .posts-table .no-posts {
        font-size: 16px;;
    }

      /* Set column widths */
  .posts-table .title-column {
    width: 150px;
  }

  .posts-table .views-column {
    width: 3px;
  }

  .posts-table .collections-column {
    width: 3px;
  }

  .posts-table .created-column {
    width: 80px;
  }
      
  }

