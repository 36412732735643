.html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: rgba(200, 200, 200, 0.1);
    margin-bottom: 50px;
}

.delete-image-btn {
    background-color: rgb(143, 34, 34);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0;
    font-family:   'ZhuqueFangsong-Regular' ; 
  }

.signup-logo {
    margin-top: 20px;
}

.logo-img{
    width: 150px;
}

.form-container {  
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    font-family:   'ZhuqueFangsong-Regular' ; 
       
}

.form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

input {
    width: 800px;
    font-size: 20PX;
    font-family:   'ZhuqueFangsong-Regular' ; 
    margin-bottom: 0;
    
}

.form-container .ql-wrapper {
    margin-top: -30px;
    width: 800px;
}

.ql-editor {
    font-family: 'ZhuqueFangsong-Regular'
  }

/* Style for H1 */
.ql-wrapper .ql-size-large {
    font-size: 20px ;  /* Define font size for H1 */
    font-family: 'ZhuqueFangsong-Regular';
  }

  .form-container .ql-size-false {
    font-size: 18px ;  /* Define font size for H2 */
    font-family: 'ZhuqueFangsong-Regular';
  }
  
  /* Style for H2 */
  .form-container .ql-size-small {
    font-size: 16px ;  /* Define font size for H2 */
    font-family: 'ZhuqueFangsong-Regular';
    margin-bottom: 0;
  }

  .form-container .ql-size-large {
    font-size: 20px ;  /* Define font size for H1 */
    font-family: 'ZhuqueFangsong-Regular';
  }

  .create-post-edit ol,
  .create-post-edit ul{
    font-size: 18px; 
  }
  
  /* Style for normal text */
  .create-post-edit p {
    font-size: 18px;  /* Define default font size for normal text */
    font-family: 'ZhuqueFangsong-Regular'
  }







textarea {
    width: 800px;
    font-size: 20PX;
    font-family:   'ZhuqueFangsong-Regular' ; 
    height: 300px;
}

.upload-image-post {
    width: 100px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
  }


select {
    font-size: 20PX;
    font-family:   'ZhuqueFangsong-Regular' ; 
    height: 30px;
    width: 150px;
    cursor: pointer;

}


select:hover {
 
    background-color: rgb(231, 231, 231);
}

.image-preview-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

}
f


.submit-file {
    cursor: pointer;
    font-size: 20px;
    font-family:   'ZhuqueFangsong-Regular' ;
}
.submitform {
    font-size: 20PX;
    font-family:   'ZhuqueFangsong-Regular' ; 
    /* margin-top: 20px; */

    cursor: pointer;
    height: 30px;
    width: 800px;
    margin-bottom: 50px;
}

.submitform:hover {
    font-weight: bold;
   
}

.submit-status {
    margin-bottom:50px;
    color: green;
    font-size: 18px;
}


@media (max-width: 768px) {

    .logo-img{
        margin-top:50px;
    }
    input, textarea, select, .submitform, .form h1{
        width: 90%; /* Make form elements responsive on smaller screens */
        margin-left: 10px;
    }

    .form-container .ql-wrapper {
        margin-left: 10px; /* Adjust left margin as needed */
        width: 92%;
    }

    .custom-select {
        width: 30%;
    }

   


    .ql-container{
        height: 500px;
     
    }



    .image-previews{
        margin-left: 20px;
    }

    .caption-input{
        margin-left: 0;
    }


    
    textarea {
        height: 200px; /* Adjust height for smaller screens */
    }
    
/* Style for H1 */
.form-container .ql-size-large {
    font-size: 18px;  /* Define font size for H1 */
    font-family: 'ZhuqueFangsong-Regular'
  }
  
  /* Style for H2 */
  .form-container .ql-size-small {
    font-size: 14px;  /* Define font size for H2 */
    font-family: 'ZhuqueFangsong-Regular'
  }
  
  /* Style for normal text */
  .ql-editor p {
    font-size: 16px;  /* Define default font size for normal text */
    font-family: 'ZhuqueFangsong-Regular'
  }

  /* Add this to your CSS file */
.ql-editor ol,
.ql-editor ul{
    font-size: 16px; /* Adjust to your desired size */
}

}



/* quill-custom-table.css */
.quill-editor .quill-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  .quill-editor .quill-table td,
  .quill-editor .quill-table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .quill-editor .quill-table th {
    background-color: #f2f2f2;
  }


  .image-preview-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .image-with-caption {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .image-with-caption:hover {
    cursor: grab;
  }
  
  .image-with-caption:active {
    cursor: grabbing;
  }
  
  
