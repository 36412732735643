
.signin-container {

    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    font-weight: 550;
}

.signin-logo {

    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
    font-size: 12px; 
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }
  .divider span {
    font-size: 18px;
    color: #535353;
  }





.gmaillogin {
    font-family:    'ZhuqueFangsong-Regular' ;  
    background-color: bisque;
    border: 1px solid grey;
    font-size: 20px; 
    margin-bottom: 10px;
    width: 255px;
    cursor: pointer;
    height: 30px;
    /* margin-left: 10px; */
 }

 .gmaillogin:hover{
    background-color: rgb(221, 181, 128);
 }

.signin {
    display: grid;
    place-items: center;
    height: 100%;
    justify-content: center;
    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
   
}

.signin-form {
    border: 0.5px solid rgba(120, 120, 120, 0.3); 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding: 0px 20px 16px 20px;

} 





.signinput,
.signinpassword {
    border: 1px solid black;
    background-color: skyblue;
    margin-bottom: 10px;
    width: 250px;
    height: 30px;
    margin-top:2px;
}


.resend-verification{

    margin-top: 10px;

    margin-bottom: 10px;
    width: 255px;
    height: 30px;
    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    font-style: italic;

}

.resend-verification:hover{
    cursor: pointer;
}

input {
    font-family:  'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    
}



.signin_password {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* justify-content: space-between; */
}


.showpassword-bx {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-bottom: 10px;
}

.showpassword-checkbx {
    /* Increase checkbox size */
    width: 20px;
    height: 20px;
    transform: scale(1.0); /* Scale it larger */
    margin-right: 10px; /* Add space between checkbox and label */
    
}

.submit {
    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    width: 256px;
    background-color: #ffd900f8;
  
    border: none;
    border-radius: 5px;
    height: 35px;
    cursor: pointer;
   
}

.submit:hover{
    background-color: #eccc12f8;
}

.signup {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
}

.signup-newaccount {
    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 16px; 
    cursor: pointer;

}

.error-message {
    color: red;
    background-color: #fdd;
    padding: 10px;
    border: 1px solid red;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
   
    .signin-logo {
       margin-top: 30px;
    }

    .gmaillogin,    
    .signinput,
    .signinpassword,
    .submit{
        margin-left: 0px;
    }
}