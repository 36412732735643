body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ZhuqueFangsong-Regular';
  src: local('ZhuqueFangsong-Regular'), url(./fonts/ZhuqueFangsong-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSerifSC-Regular';
  src: local('NotoSerifSC-Regular'), url(./fonts/NotoSerifSC-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'YingHei';
  src: local('YingHei'), url(./fonts/YingHei.ttf) format('truetype');
}

@font-face {
  font-family: 'YZhuTi';
  src: local('ZhuTi'), url(./fonts/ZhuTi.ttf) format('truetype');
}





