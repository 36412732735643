
.footer-wrapper {
    width: 100%;
    background-color:  #ebebeb; 
    /* font-family: 'NotoSerifSC-Regular'; */
    margin-top: 0px;

    padding-bottom: 60px;
    padding-top: 20px;
    text-align: center;
    margin-bottom: -40px;
}

.website_title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.footer p {
    font-size: 16px;
}


/* For smaller screens (phones) */
@media (max-width: 768px) {
   
    .footer h1 {
        font-size: 18px;
    }
    .footer p {
        font-size: 14px;
    }
    
    
}