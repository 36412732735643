
.comments-container {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 30px;
}



.comment_input textarea{
    height: auto;
    width: 500px;
}

.comment_input textarea::placeholder{
    font-size:16px;
}


.comment-header,
.reply-header{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 350px;
    align-items: center;
    color: rgb(100,100,100);
    font-size: 16px;
}

.user-date {
    text-decoration: underline;
}
.comment-date,
.reply-date {
    font-size: 14px;
    margin-left: 5px;
}


.reply-delete{
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items: center;
    gap: 10px;
}

.reply-name:hover,
.delete-icon:hover{
    cursor: pointer;
}

.reply-input{
    width: 500px;
    height: auto;
}

.reply-input::placeholder{
    font-size: 16px;
}

.replies-container {
    margin-left: 40px;
}

@media (max-width: 600px) {

    .replies-container.reply-date,
    .replies-container .reply-contents,
    .reply-input::placeholder,
    .comment-contents,
    .comment-user{
        font-size: 14px;
    }

    .comment-date, .reply-date {
    font-size: 12px;
    margin-left: 5px;
}

    .comment_input textarea{
        margin-left: 0;
        font-size: 16px;

    }



    .reply-input{
        width: 300px;
        height: auto;
    }

  


    .comment-header,
    .reply-header{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 150px;
    align-items: center;
    color: rgb(100,100,100);
    font-size: 16px;
}

    
}