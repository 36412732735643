.deleted-posts{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.deleted-posts .deleted-post-logo {
    position: absolute;
    top: 50px;
    margin-top: 0px;
   margin-left: 50px;
}

.deleted-posts .title_t{
    position: absolute;
    top: 220px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 50px;

    font-size: 30px;
    margin-bottom: 0px;
    font-family: 'ZhuqueFangsong-Regular';
    text-decoration: underline;
}

.deleted-posts .title-post{
    text-align: center;
    font-size: 20px;
    font-family: 'ZhuqueFangsong-Regular';
    text-decoration: underline;
    margin-bottom: 10px;
}

.post-info {
    border: 0.5px solid rgba(120, 120, 120, 0.5);
    margin-bottom: 10px;
    font-family: 'ZhuqueFangsong-Regular' ;
    font-size: 20px;
    width: 200px;
    height: auto;
    padding: 20px;

    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
   

} 

.post-info .info {
    font-size: 14px;
    margin: 0;
}

.post-info .deleted-post-desccription {
    font-size: 16px;
}

.post-info button {
    font-size: 14px;
}

.post-info button:hover {
   cursor: pointer;
}

.post-ul {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 80px;

}

.deleted-posts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    width: 100%;
    gap: 20px;
    padding: 20px;
    font-family: 'ZhuqueFangsong-Regular';
}