.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: #e6f7ff; */
    /* margin-top: 20px; */
    background-color: #e9e9e9;

}

.page-header {
    position: fixed;
    top:0;
    left: 0;
    margin-bottom: 10px;
}


.clusters {
    margin-top: 40px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-template-rows: auto;
    gap: 10px;
    max-width: 1000px;
    width: 100%;
    border: 0.5px;
}



.cluster_container {
  
    height: 500px;
    border: 0.5px solid lightgrey;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
}

.section_name {
    /* padding-left: 10px;    */
    height: 30px;
    margin-top: 0px;
    padding-top: 10px;
    /* width: 100%; */
    background-color: lightblue;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'ZhuqueFangsong-Regular';
    font-weight: 800;
    /* border: solid 0.5px rgb(247, 8, 8); */
    z-index: 100;
    
}

.section_name a {
    color: rgb(2, 103, 255);
    font-weight: 800;
    text-decoration: none;
}

.section_name a:active {
    /* color: purple;  */
    text-decoration: underline;
}

.section_name {
    margin-top: 0px;
}

.posts-container {
    margin-top: -27px;
    background-color: #f0f8ff; /* Set the background color only for the posts area */
    height: 390px; /* Fixed minimum height */
    padding: 10px;
    /* border-radius: 5px;  */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    
    font-family: 'ZhuqueFangsong-Regular';
    border: solid 0.2px rgba(136, 136, 136, 0.3);
}


/* .latest-posts {
    background-color: aliceblue;
} */
.posts_container {
    display: flex;
    flex-direction: column;
}

.post {
    margin-bottom: 12px; /* Add spacing between posts */
    margin-left: 20px;
  
}


.post-link {
    color: black; /* Change the color to whatever you like */
    text-decoration: none; /* Remove underline, if needed */
}

.post-link:hover {
    color: rgb(196, 18, 12); /* Change color on hover, if desired */
    text-decoration: underline;
}


/* For smaller screens (phones) */
@media (max-width: 768px) {
    .clusters {
        grid-template-columns: 1fr; 
        /* grid-template-rows: repeat(8, 1fr);  */
        grid-template-rows: auto;
        /* width: 100%; */
    }
    /* .search-input {
        font-size: 16px;
      } */

      .section_name{
        /* font-size: 14px; */
        font-size: 20px;
        height: 10px;
        padding-bottom: 32px;
        /* padding-top: 10px; */
     
    }
    
      .post,
      .posts-container .no-homepage-post {
        margin-left: 10px; 
        font-size: 18px;
    }

    .cluster .posts-container {
        height: 390px; /* Fixed minimum height */
    }

  

    .cluster_container {
        height: auto; 
        /* max-width: 100%;  */
    }

 
    .posts-container {
        background-color: #f0f8ff; /* Set the background color only for the posts area */
        /* height: 380px;  */
        /* width: 100%;  */
        height: 270px;
    }

    
}
