.header-container {
  background-color: #ebebeb; 
  width: 100vw;
  font-size: 20px;
    
  font-family: "YingHei";
  position: sticky;
  top: -1px;
  /* background-color: #c77d7d; */

  padding: 0; 
  margin: 0;  

}
  

.navbar {
  /* max-width: 1000px; */
  /* width: 50%; */
  width: auto;
  height: 80px;
  /* background-color: #d81414;  */
  padding-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;   
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  z-index: 50;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(240, 240, 240);

  /* background-color: #c7bc7d; */
}

.left-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: rgb(15, 173, 226); */
  /* width:100px; */
  /* gap: 20px; */
  z-index: 1000;
}

.logo {
  /* flex-shrink: 0;  */
  margin-right: 10px;
  display: flex;
}

.links {
  /* margin-left: 10px;
  margin-right: 20px; */
  /* flex-shrink: 0; */
}

.link {
  text-decoration: none;
  color: rgb(70, 70, 70);
  white-space: nowrap;

}

.link:hover {
  text-decoration: underline;
  text-decoration-color: rgb(8, 151, 80);
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  color: rgb(14, 120, 196);
  font-weight: bold;
  
}

.middle-section {
    flex: 1;
    margin-left: 30px;
    margin-right: 30px;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #f70909; */

}

input:focus {
  outline: none; /* Removes the default browser focus outline */
    border: 1px solid black; /* Change to green border when focused */
}

.search-input {
  min-width: 40px;
  padding-left: 15px;
  height: 33px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 20px;
  border: 0.5px solid #c0c0c0;
  /* border-radius: 2px; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: inset 1px 2px 3px rgba(0,0,0,0.05);
  font-family: 'ZhuqueFangsong-Regular';

  flex-shrink: 0;
  flex:1;
  margin-left: 0;
  
}

.search-input::placeholder {
  height: 34px;
  flex:1;
  font-size: 20px;
}

.search_button {

  height: 37px;
  width: 35px;
  /* width: auto; */
  border: 0.5px solid #c0c0c0;
  padding-top: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;

  margin-left: 0px;



  display: flex;
  justify-content: center;
  align-items: center;
}





.search-active {
  border: 1px solid rgb(0, 0, 0); 
}


.search_icon {
  height: 25px;    
}

.post-notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.notification_icon {
  height: 25px; 
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 5px; 
  display: flex;
  justify-content: cneter;
  align-items: center;     
}

.notification_icon:hover {
  cursor: pointer;
}

.notification-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notification-container .tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #cacaca;
  border-radius: 2px;
  padding: 4px 4px;
  font-size: 12px;
  opacity: 0;
  white-space: nowrap;
}

.notification-container:hover .tooltip {
  left: -20%;
  top: 40px;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}





.in_out {
  display: flex;
  justify-content: space-between;
  gap: 3px;
  flex-shrink: 0;
  padding-right: 10px;
  margin-right: 10px;
}

.register,
.login {
  border-radius: 5px;
  border-width: 0.5px;
  border-color: rgba(22, 22, 22, 0.5);
  cursor: pointer;
  font-size: 20px;
  font-family: 'ZhuqueFangsong-Regular';
  white-space: nowrap;
}

.login:hover {
  background-color: #ffd900f8;
}

.resources {
  position: absolute;
  top: 60px;
  width: 80%;
  height: 100px;
  align-items: center;
  border: 0.5px solid lightgray;
  margin-left: 10%;
}

.dish, .fun {
  width: 50px;
  height: 50px;
  object-fit: cover;
}


.user-container{
  /* background-color: antiquewhite; */
}

.user-container{
  display: flex;
  justify-content: center;
  gap: 0;
  align-items: center;
  flex-shrink: 0;
  /* width: 160px; */
}

.createpost{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.createpost a {
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
}

.createpost:hover {
  text-decoration: underline;
}

.createpost, .signout-btn {
  font-size: 20px;
  font-family: 'ZhuqueFangsong-Regular';
  cursor: pointer;
  font-weight: bold;
  flex-shrink: 0;
}

.createpost:hover, .signout-btn:hover {
  background-color: lightblue;
}

.createpost {
  margin-right: 20px;
  padding: 2px;
}

.posts-btn {
  font-size: 20px;
  font-family: 'ZhuqueFangsong-Regular';
  /* margin-left: 100px; */
  font-weight: bold;
}

.posts-btn:hover {
  background-color: antiquewhite;
  cursor: pointer;
}


.notification-container {
  position: relative;
  margin-left: 20px;
  display: inline-block;
}


.notification_icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  justify-content: center;
}


.red-dot {
  position: absolute;
  top: 0px;
  right: 19px;
  width: 10px;
  height: 10px;
  background-color: rgb(214, 10, 10);
  border-radius: 50%;
}


.tooltip {
  font-size: 12px;
  color: #555;
  text-align: center;
  position: absolute;
  top: 30px;
  width: 100%;
  display: none;
}

.notification-container:hover .tooltip {
  display: block;
}


.notification-item {
  display: flex;
  justify-content: center;
 
}

.notification-list {
  position: absolute;

  top: 45px;
  right: -20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 500px;
  overflow-y: auto;
  display: none;
  font-family: 'ZhuqueFangsong-Regular';

}


.notification-container.show .notification-list {
  display: block;
}


.notification-item .notification-message.notification-approved {
  color: green !important;
}


.notification-item .notification-message.notification-rejected {
  color: red !important;
}

.notification-item {
  display: flex;
  align-items: center; 
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.notification-list .notification-message p,
.notification-list .notification-rejected p,
.notification-list .notification-approved p,
.notification-list .notification-item {
font-size: 18px; }



.unread-spot {
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%; 
  margin-right: 10px; 
  display: inline-block; 
  vertical-align: middle; 
}

.notification-message,
.notification-item {
  font-size: 16px;
  color: #333;
}

.notification-timestamp {
  font-size: 16px;
  color: #777;
}


.user-info {
  position: relative;
  cursor: pointer;
}


.username-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.username {
  font-size: 20px;
  font-family: 'ZhuqueFangsong-Regular';
  white-space: nowrap;

}


.dropdown-icon {
  margin-left: 1px; 
  vertical-align: middle;
  font-size: 15px; 
  color: #000000;
  display: inline-block;
  margin-top: 7px;
}

.username-container:hover .username,
.username-container:hover .dropdown-icon {
  color: rgba(0, 0, 0, 1); 
  cursor: pointer; 
  font-weight: 600;
}

.user-menu {
  position: absolute;
  top: 60px; 
  right: 10px; 
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  width: 120px;
  font-family: 'ZhuqueFangsong-Regular';
}

.user-menu::before {
  content: '';
  position: absolute;
  top: -10px; 
  right: 10px; 
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent white transparent; 
}

.user-menu a {
  display: block;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
}

.user-menu .myposts,
.user-menu .mycollections {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.user-menu .exit-btn {
  display: block;
  width: 100%;

  border: none;
  color: red;
  cursor: pointer;
  font-family: 'ZhuqueFangsong-Regular';
  font-weight: 550;
  font-size: 18px;
}





.user-menu a:hover,
.user-menu button:hover {
  text-decoration: underline;
}


input[type="search"] {
  -webkit-appearance: none; /* Disable default iOS search input styling */
  appearance: none; /* Disable default styling in modern browsers */
  margin: 0; /* Remove default margins */
  padding: 8px; /* Adjust padding to your preference */
  width: 100%; /* Ensure it takes up full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

input[type="search"]::-webkit-search-decoration, /* Remove the clear (x) button */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; /* Remove default search decorations */
}


/* Media query for mobile devices */
@media (max-width: 768px) {

  .navbar, .createpost,
  .username, .posts-btn, .login{
    font-size: 18px;
  }

  .search-input::placeholder, 
  .search-input{
    font-size: 16px;
  }

  .left-section .azlife-logo {
    width: 60px;
    justify-content: center;
  align-items: center;
  
  }

  /* .navbar{
    height: 60px;
  } */

  .login{
    padding: 2px;
  }

  .post-notification .createpost{
    padding: 5px;
  }


  .search-input {
    height: 26px;
    min-width: 30px;
    padding-left: 10px;
  }
  
  .search_button {
    height: 34px;
    width: 30px;
  }

  .search-input::placeholder {
    height: 20px;
  }
  


  
  .search_icon {
    height: 20px;    
  }


  .navbar{
    padding-left: 20px;
    padding-right: 20px;
  }

  .middle-section {
    margin-left:5px;
    margin-right: 5px;
  }

  /* Optional: Add custom spacing */
  .post-notification {
    margin-left: 1px;
  }

  .user-container {
    display: flex;
    align-items: center;
    gap: 6px; /* Adjust to control the gap between items */
  }
  
  .user-container .post-notification,
  .user-container .user-info,
  .user-container.createpost {
    display: flex;
    align-items: center;
    margin: 0; /* Ensure no extra margin is added */
    padding: 0px; /* Ensure no extra padding is added */
  }
  
  .post-notification .createpost {
    margin: 0;
    padding: 0;
    border-radius: 5px;
   
  }
  
  .notification_icon {
    margin: 0;
    padding: 0;
  }
  
  .user-info {
    margin: 0;
    padding: 0;
  }
  
  .username-container {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .username {
    margin: 0; /* Ensure no extra margin on text */
    padding: 0;
  }
  
  button, .notification_icon, .username-container {
    margin: 0;
    padding: 0;
  }

  .user-menu {
    width: 80px;
  top: 30PX;}

  
  .user-menu .mycollections,
  .user-menu .myposts,
  .user-menu .exit-btn {
    font-size: 16px;
  }

    .notification-list .notification-message p,
  .notification-list .notification-rejected p,
  .notification-list .notification-approved p,
  .notification-list .notification-item {
  font-size: 16px; }

  /* .post-notification .notification-message {
    font-size: 14px;
    width:200px;
  } */


  .red-dot {
    position: absolute;
    top: 0px;
    right: -3px;
    width: 8px;
    height: 8px;
    background-color: rgb(214, 10, 10);
    border-radius: 50%;
  }

  .notification-list .notification-message p,
  .notification-list .notification-rejected p,
  .notification-list .notification-approved p,
  .notification-list .notification-item {
  font-size: 16px; 
  /* width:200px; */
}

}