html, body {
    height: 100%;
    margin: 0;
    display: grid;
   
    place-items: center; /* Centers content both horizontally and vertically */
}

.signup-container {

    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 11px; 
    font-weight: 550;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-logo {

    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;

}


.signup {

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
   
}

.signup-form {
    border: 0.5px solid rgba(120, 120, 120, 0.3); 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding: 0px 20px 16px 20px;
} 


.signupusername,
.signupemail,
.signuppassword,
.confirmpassword {
    border: 1px solid black;
    background-color: rgba(180,180,180, 0.3);
    margin-bottom: 10px;
    width: 250px;
    height:30px;
    margin-top:2px;
    font-size: 20px;
}

input {
    font-family:  'ZhuqueFangsong-Regular' ; 
    /* font-size: 16px;  */
    height: 40px;
    color:black;
    
}


.showpassword-box {
    display: flex;
    align-items: center;
    gap: 0px;
}

.showpassword-checkbox {
    /* Increase checkbox size */
    width: 20px;
    height: 20px;
    transform: scale(1.0); /* Scale it larger */
    margin-right: 10px; /* Add space between checkbox and label */
}

.showpassword-label {
    cursor: pointer; /* Changes cursor to pointer when hovering over the label */
}



.register {
    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    width: 255px;
    height: 30px;
    background-color: rgb(253, 215, 2);
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;  
}

.register:hover {
    background-color: orange;
}

.signin_part {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px; 
   
}

.signin-account {
    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    cursor: pointer;

}