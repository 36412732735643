html, body {
    height: 100%;
    margin: 0;
    display: grid;
   
    place-items: center; /* Centers content both horizontally and vertically */
}

.container {

    font-family:    'ZhuqueFangsong-Regular' ; 
    font-size: 20px; 
    font-weight: 550;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.logo_password {

    display: flex;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 0px;
    gap: 10px;
    font-size: 16px; 
}

.email-address {
    margin-top: -10px;
    width: 250px;
    height: 30px;
    font-size: 20px;
    
    font-family: 'ZhuqueFangsong-Regular' ; 

}

.reset-pwd{
    width: 258px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;

    background-color: #ffd900f8;
    border: none;
    border-radius: 10px;
    font-family:    'ZhuqueFangsong-Regular' ; 
}

.reset-pwd:hover{
    background-color: #eccb0ed8;

}

.forgot-pwd {
    display: flex;
    flex-direction: column;
    gap: 20px;
}