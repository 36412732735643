
.no-search-posts{margin-left: 20vw; font-size: 16px;}

.collection-container2 h3{
    font-size: 18px;
}

.header-category2 h3{
    font-size: 18px
};

@media (max-width: 748px) {
    .collection-container h3{
    font-size: 14px;}

    .collection-container h3,
    .collection-container .posts-table {
        margin-left: -30px;
    }

    .collection-container2 .no-search-posts {
        font-size: 14px;
        margin-left: 0;
    }

    .search-posts h3{
        font-size: 18px
    };

}