.save-btn, 
.cancel-btn,.edit-btn,
.approve-btn,
.reject-btn {
    width: 100px;
    height:30px;
    font-size: 20px;
    font-family: 'ZhuqueFangsong-Regular' ;
    margin-left: 10px;
    margin-top: 10px;
   
}


.save-btn:hover,
.cancel-btn:hover,
.edit-btn:hover,
.reject-btn:hover,
.approve-btn:hover,
.reapprove-btn:hover {
    cursor: pointer;
    background-color: brown;
    color:white;
}

.post-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.edit-btn {
    margin-bottom: 10px;
}

p{
    font-size: 20px;
}

.reject-text {
    width: auto;
}

.individual-post {
    border: 0.5px solid rgba(120, 120, 120, 0.5);
    margin-bottom: 10px;
    font-family: 'ZhuqueFangsong-Regular' ;
    font-size: 20px;
    width: 300px;
    height: auto;
    padding: 20px;

} 


.edit-input, 
.edit-textarea {
    width: 300px;
    font-family: 'ZhuqueFangsong-Regular' ;
    font-size: 20px;
}
