.wrapper{
    font-size: 20px;
    font-family: 'ZhuqueFangsong-Regular';
    display: grid;
    place-items: center;      /* Center horizontally and vertically */
    height:100%;    
    /* background-color: rgba(150, 150, 150, 0.1);        */
    width: 100vw;
    /* height: 100vh; */

}

.admin-container {
    margin-top: 50px;
    /* left: 20%; */
    z-index: 100; /* Ensure it's above other elements */
    /* overflow-y: auto; */
  
}

.admin-post-link{
    margin-bottom: 5px;
}

.search-bar{
    display: flex;
    justify-self: flex-start;
  
}

.title-info {
    text-decoration: underline;
    font-weight: 600;
}

.username-input {
    width: 400px;
    height: auto;
    font-size: 30px;
    background-color: rgba(50, 50, 50, 0.1);
}

.serch-icon{
    width: 30px;
}
.search-btn{
    width:60px;
    display: flex;
    align-items: center;
    justify-content: center;
}




.search-btn:hover{
    cursor: pointer;
}

.block-btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}





.block-btn,
.review-post-button,
.deleted-post-button{
    font-size: 20px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 10px;
    /* margin-top: 20px; */
    transition: 0.3s;
    border-radius: 15px;
   
    
}


.review-post-button,
.deleted-post-button{
    background-color: antiquewhite;
    border: solid 0.5px;
    /* padding: 10px;
    border-radius: 15px; */
    
}

.block-btn:hover,
.review-post-button:hover,
.deleted-post-button:hover{
    cursor: pointer;
    /* color: rgb(141, 37, 37);
    background-color: rgb(150, 201, 201); */
}

.posts-info,
.basic-info,
.comments-info,
.replies-info,
.blocked-history {
    margin-top: 70px;
}

.replies-bot{
    margin-bottom: 100px;
}

@media (max-width: 768px) {

    
.username-input {
    width: 250px;
    font-size: 18px;
}

.serch-icon{
    width: 30px;
}
.search-btn{
    width:60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-btn,
.review-post-button,
.deleted-post-button{
    font-size: 16px;
    margin-left:10px;
    border-radius: 20px;

}

   .posts-info h3,
    .basic-info h3,
    .comments-info h3,
    .replies-info h3,
    .blocked-history h3 {
        font-size:18px;
        margin-left:10px;
    }

   .wrapper {
        font-size:16px;
        margin-left:20px;
    }
    .blocked-history p{
        font-size:16px;
    }
    
}