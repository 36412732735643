.header-line-post
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
 
  }

  .collection-container2 {
    position: absolute;
    top:100px;
    left: 50px;
    /* margin-top: 50px; */
    font-size: 18px;
    font-family: 'ZhuqueFangsong-Regular';
     
}

.collection-container2  h3{
    margin-left: 20vw;
    text-decoration: underline;
}
  .back-btn:hover{
    cursor: pointer;
  }
  