.collection-header {
    position: fixed;
    top:0;
    left:0;
    z-index:100;
   
}



.collection-container {
    position: absolute;
    top:100px;
    left: 50px;
    /* margin-top: 50px; */
    font-size: 18px;
    font-family: 'ZhuqueFangsong-Regular';
     
}

.collection-container h3{
    margin-left: 20vw;
    text-decoration: underline;
}

.no-posts {
    margin-left: 20vw;
}

table {
    width: 100%;
    border-collapse: collapse; /* Remove gaps between table cells */
    margin-left: 20vw;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Add a line between rows */
  }
  
  th {
    background-color: #f0f8ff; /* Light background for header */
    font-weight: bold;
    text-align: left;
  }
  
  td {
    text-align: left;
  }
  
  /* Set column widths */
  .title-column {
    width: 400px;
  }

  .views-column {
    width: 50px;
  }

  .collections-column {
    width: 50px;
  }

  .created-column {
    width: 50px;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Add stripe effect for rows */
  }
  
  tbody tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
  }


  @media (max-width: 768px) {

    .collection-container h3,
    table,
    .no-posts{
        margin-left: -30px;
       
    }

    .collection-container h3{
        font-size: 14px;
    }

    th, td,  
    .no-posts {
        font-size: 12px;;
    }

      /* Set column widths */
  .title-column {
    width: 300px;
  }

  .views-column {
    width: 10px;
  }

  .collections-column {
    width: 10px;
  }

  .created-column {
    width: 50px;
  }
      
  }

  @media (min-width: 768px) and (max-width: 1024px) {

    .collection-container h3,
    table,
    .no-posts{
        margin-left: 10px;
       
    }
  }